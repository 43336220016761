<template>
<div>
    <SecurityProfile />
</div>
</template>

<script>
import SecurityProfile from '../components/dashboard/ProfileSecurityMobile.vue'
export default {
    components: {
        SecurityProfile
    }
}
</script>

<style>
    