<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">
                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
  <h4 class="mb-2 head-font mt-3">

                    <img src="../../assets/images/chev.png" loading="lazy" alt="" height="18" @click="$router.go(-1)" class="pr-4 pb-1">
                    Payout & Security </h4>


   <section id="tabs" class="project-tab">
            <nav>
              <div class="nav nav-tabs nav-fill" style="max-width: 78%;" id="nav-tab" role="tablist">
               
                <a
                  class="nav-item nav-link active"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >Payout</a>

                  <a
                  class="nav-item nav-link"
                  id="nav-security-tab"
                  data-toggle="tab"
                  href="#nav-security"
                  role="tab"
                  aria-controls="nav-security"
                  aria-selected="false"
                >Security</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              


              <!-- PROFILE NAV -->
              <div
                class="tab-pane show active fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >


 <div class="display-div tab-div-2" style="padding:20px 5px">
                  <div class="row pb-3">
                    <div class="col-md-4 col-12">
                       <div >
    <h5 class="fw-600">Payout Details</h5>
                       </div>
                    </div>
                    <div class="col-md-12 col-12">
                        <div style="display:flex; flex-direction:column">
<div style="flex-basis:100%" class="mt-3">
    <p style="color:#828282">Bank Name</p>
                <select @change="getBankSelected($event)" :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}" class="text-center">
                                            <option v-for="bank in bankNames" :value="bank.id" :key="bank.id" :selected="bank.name == userProfileInfo.bank_name">
                                                {{bank.name}}</option>
                                        </select>
                        </div>
                                       
<div style="flex-basis:100%" class="mt-3">
    <p style="color:#828282">Account Number</p>
                                       <input  type="text" autocomplete="off" :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"  v-model="userProfileInfo.bank_account_no" maxlength="10" @input="getBankNames" style="min-width:100%">
              
                        </div>
                        </div>
                        <div style="display:flex;">
<div style="flex-basis:20%">
  <div class="mt-3" v-if="userProfileInfo.bvn == null || userProfileInfo.bvn == ''">
    <p style="color:#828282">BVN   <span style="color:#000; pointer-events:all"> <i class="fa fa-question-circle"  title="we need your bvn to validate your bank account details, we don't store your bvn" v-tippy='{ arrow : true, theme:"light", placement:"bottom", arrowType : "round"}' ></i></span></p>
                                       
                                         <div>

                                                <input v-model="bvn" :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"  type="number" autocomplete="off" maxlength="11" style="min-width:100%">
                                         </div>
              
                        </div>
                        </div>
                                       
                        </div>
<div v-if="userProfileInfo.bank_account_name !== null" class="animated fadeIn">
    
                                                <p  class="mt-3" style="color:#828282">{{tran.acc_name}}</p>
                                                <p style="font-size:14px; font-weight:600" v-if="userProfileInfo.bank_account_name !== null">{{userProfileInfo.bank_account_name}}</p>
              
                        </div>
                        <div>

                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="searchingAccountName" ></span>
                        </div>
                    </div>
                    
                  </div>
                </div>
 <div class="display-div tab-div-2"  style="align-items:center; min-height:56px; margin-top:-20px;padding:20px 5px">
                  <div class="row" >
                   
                    <div class="col-md-4 col-12">
                      
                       
                    </div>
     <div class="col-md-6 col-12">

<div class="">
     <button class="text-left" style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px" v-if="!editBankDetail"  @click.stop="editUserBankDetail">
                   
                                                
                                              <p> Edit details  </p>
                                                
                                           
                                         
            </button>
            
            </div>
          
            <div v-if="editBankDetail">
            <button class="mr-4" style="background:transparent; border:1px solid transparent;min-width:150px;    font-size: 14px; color:#4F4F4F"                 @click="stopEditUserBankDetail">
                   <p> Cancel </p> 
            </button>
            <button style="background:#0094FF; border:1px solid #0094FF; font-weight:300;     font-size: 14px; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px"  @click="updateBankDetails">
                                                            <p>     {{editBankDetailLoader ? '' : 'Save Changes'}} </p>
                                                
                                            
                                                
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="editBankDetailLoader">
                                         </span>
            </button>
            </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
                </div>

                <!-- Security TAB -->
              <div
                class="tab-pane fade"
                id="nav-security"
                role="tabpanel"
                aria-labelledby="nav-security-tab"
              >
                         
<div class="display-div tab-div-2" style="padding:0px 0px">
                  <div class="row pb-3" style="padding:20px 5px; border-bottom:1px solid #E0E0E0">
                    <div class="col-md-4 col-12 pb-3">
                       <div >
    <h5 class="fw-600 ">PIN</h5>
                       </div>
                    </div>
                    <div class="col-md-8 col-12">
                        <div style="display:flex;">
<div style="flex-basis:60%">

                                                      
                                                     

<p style="color:#0094FF" class="pb-2 point-cursor"  data-toggle="modal" v-if="userProfileInfo.has_pin" @click="openChangePinModal">Change PIN</p>
                          <p style="color:#0094FF" class="pb-2 point-cursor"
                                                            v-if="userProfileInfo.has_pin" @click="openforgotPinModal">
                                                            Forgot PIN?</p>
    <p style="color:#0094FF" class="pb-2 point-cursor" v-if="userProfileInfo.has_pin == false"  data-toggle="modal"  data-target="#createPinModal">Create PIN</p>
                <p style="color:#828282;"  > Choose something more memorable</p>
                        </div>
                                       

                        </div>
                    </div>
                    
                  </div>
                </div>

                 <div class="display-div tab-div-2"  style="padding:20px 5px; margin-top:10px">
                  <div class="row">
                   
                    <div class="col-md-4 col-12 pb-3">
                        <div>

                        <h5 class="fw-600">Password</h5>
                        </div>
                       
                    </div>
     <div class="col-md-6 col-12">

<div style="display:flex;">
  <div style="flex-basis:60%">
    <p style="color:#0094FF" class="pb-2 point-cursor" @click="openChangePasswordModal">Change Password</p>
                <p style="color:#828282;" > Choose this option to have your password updated to something better</p>
                        </div>

                        </div>
</div>
                      
    
                    
                    
                  </div>
                </div>
                </div>
            </div>
        </section>









                <template v-if="searchString == ''">
                    <div>
                        <div class="profile-div pb-4">
                           

                                                          



    <div class="modal fade" id="changePinModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header" style="border-bottom:1px solid #F2F2F2 !important;">
                                                                                            <div>

                                                                                            <h6 class="modal-title head-font" id="exampleModalLongTitle">Change PIN
                                                                                       </h6>
                                                                                            <p style="color:#828282;; font-size:14px">      {{userProfileInfo.email}}
                                                                                                 </p>
                                                                                            </div>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
     <div class="modal-body security-modal">
                                                                                            <p style="color:#828282;">Enter your current PIN</p>
                                                                                                <div class="eye-div">
                                <input
                    v-model="oldPin"
                    :class="{ 'enable-input':true }"
                    :type="hideInput"
                    autocomplete="off"
                    maxlength="4">
                                <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                            </div>
                                                                                               <div style="width:60%; border-top:1px solid #E0E0E0" class="mt-4 mb-4"></div>
                                                                                                  <p style="color:#828282;"> Type a 4 digit PIN</p>
                                                                                               <input  :type="hideInput"        v-model="newPin" autocomplete="off" :class="{ 'enable-input':true }"   maxlength="4" placeholder="">
                                                                                                  <p style="color:#828282;" class="mt-3">Retype PIN</p>
                                                                                               <input :type="hideInput"     maxlength="4"   v-model="retypeNewPin"     autocomplete="off" :class="{ 'enable-input':true }" placeholder="">

                                                                                         <p style="color:#0094FF;" v-if="newPin !== retypeNewPin"   @click.prevent="changePin" class="text-center point-cursor pt-4 pb-4" :class="{'fade-elem': oldPin.length < 4}">Save new PIN</p>
                                                                                         <p style="color:#0094FF;" :class="{'fade-elem': oldPin.length < 4 }" v-if='!loader && newPin == retypeNewPin'   @click.prevent="changePin" class="text-center point-cursor pt-4 pb-4">Save new PIN</p>
                                                                                         <div class="text-center pt-4 pb-4" v-if="loader">
                                                                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>
                                                                                                     
                                                                                         </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


    <div class="modal fade" id="createPinModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header" style="border-bottom:1px solid #F2F2F2 !important;">
                                                                                            <div>

                                                                                            <h6 class="modal-title head-font" id="exampleModalLongTitle">Create PIN
                                                                                       </h6>
                                                                                            <p style="color:#828282;; font-size:14px">      {{userProfileInfo.email}}
                                                                                                 </p>
                                                                                            </div>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
             <div class="modal-body security-modal">
                                                                                            <p style="color:#828282;">Type a 4 digit PIN</p>
                                                                                                <div class="eye-div">
                    <input v-model="pin"

                    :class="{ 'enable-input':true }"
                                 id="createPinInput"
                    :type="hideInput"
                    
                    autocomplete="off" maxlength="4">

                                <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>

                            </div>
                                                                                             
                                                                                                  <p style="color:#828282;" class="mt-4">Retype PIN</p>
                                                                                               <input    maxlength="4" :type="hideInput" v-model="retypeCreateNewPin"  autocomplete="off" :class="{ 'enable-input':true }" placeholder="">
                                                                                                

                                                                                         <p style="color:#0094FF;" v-if="!loader"   @click.prevent="createPin" :class="{'fade-elem': pin.length < 4}" class="text-center point-cursor pt-4 pb-4">Create PIN</p>
                                                                                         <div class="text-center pt-4 pb-4" v-if="loader">
                                                                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>
                                                                                                     
                                                                                         </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

    <div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header" style="border-bottom:1px solid #F2F2F2 !important;">
                                                                                            <div>

                                                                                            <h6 class="modal-title head-font" id="exampleModalLongTitle">Change Password
                                                                                       </h6>
                                                                                            <p style="color:#828282;; font-size:14px">      {{userProfileInfo.email}}
                                                                                                 </p>
                                                                                            </div>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        
                                                                                        <div class="modal-body security-modal">
                                                                                            <p style="color:#828282;">Type old Password</p>
                                                                                                <div class="eye-div">
                                <input
                    v-model="oldPassword"
                    :class="{ 'enable-input':true }"
                    :type="hideInputForPassword"
                    autocomplete="off">
                                <i class="fa fa-eye-slash" @click="showInputTextForPas" v-if="showEyeForPas"></i>
                                <i class="fa fa-eye" @click="hideInputTextForPas" v-if="hideEyeForPas"></i>
                            </div>
                                                                                             
                                                                                                  <p style="color:#828282;" class="mt-4">New password</p>
                                                                                               <input   :type="hideInputForPassword" v-model="newPassword" autocomplete="off" :class="{ 'enable-input':true }" placeholder="">
                                                                                                  <p style="color:#828282;" class="mt-4">Retype New Password</p>
                                                                                               <input v-model="retypePassword" :type="hideInputForPassword" autocomplete="off" :class="{ 'enable-input':true }" placeholder="">
                                                                                                

                                                                                         <p style="color:#0094FF;" v-if="newPassword !== retypePassword"   @click.prevent="changePassword" class="text-center point-cursor pt-4 pb-4" >Save Password</p>
                                                                                         <p style="color:#0094FF;" @click.prevent="changePassword" class="text-center point-cursor pt-4 pb-4" v-if='!loader && newPassword == retypePassword'>Save Password</p>
                                                                                         <div class="text-center pt-4 pb-4" v-if="loader">
                                                                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>
                                                                                                     
                                                                                         </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>





    <div class="modal fade" id="forgotPin" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header" style="border-bottom:1px solid #F2F2F2 !important;">
                                                                                            <div>

                                                                                            <h6 class="modal-title head-font" id="exampleModalLongTitle">Forgot Pin
                                                                                       </h6>
                                                                                           
                                                                                            </div>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        
                                                                                        <div class="modal-body security-modal" style=" padding: 20px 32px !important;">
                                                                                                <p style="color:#828282;" class="pb-3">Your PIN is critical to protecting your reQuid account, if you have forgotten it, you can request for a reset</p>
                                                                                                <p style="color:#828282;" class="pb-3">If you request a reset, you will receive instructions via email, but as an extra layer of protection, you can only take action after{{userProfileInfo.pin_reset_wait_time}} hours</p>
                                                                                                 <p style="color:#828282;" class="pb-3"><b>Note:</b> you will need access to your email and registered phone number.</p>
                                                                                        <p style="color:#828282;" class="pb-3"><b> Confirm your reQuid password : </b></p>
                                                                                                <div class="eye-div">
                                <input
                    v-model="oldPassword"
                    :class="{ 'enable-input':true }"
                    :type="hideInputForPassword"
                       readonly onfocus="this.removeAttribute('readonly');"
                    autocomplete="off" style="text-align:left">
                                <i class="fa fa-eye-slash" @click="showInputTextForPas" v-if="showEyeForPas" style="top: 15px;"></i>
                                <i class="fa fa-eye" @click="hideInputTextForPas" v-if="hideEyeForPas" style="top: 15px;"></i>
                            </div>
                                                                                             
                                                                                              
                                                                                       
                                                                                         <p style="color:#0094FF;" @click.prevent="forgotPin" class="text-center point-cursor pt-4 pb-4" v-if='!loader '>Continue </p>
                                                                                         <div class="text-center pt-4 pb-4" v-if="loader">
                                                                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>
                                                                                                     
                                                                                         </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
















                                                                        </div>
                                                                    </div>
                </template>
                <template v-if="searchString !== ''">
                    <searchProduct />
                </template>
            </div>
        </div>
    </Wrapper>
             
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Footer from '../partials/Footer'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations";
import searchProduct from "./SearchProduct";
import http from "../../http/index";
import api from "../../apis/index";
import {
    commonJs
} from './mixins/commonJs.js';
import {
    profileMixin
} from './mixins/profileMixin.js';

import {
    mapState
} from "vuex";
import PictureInput from 'vue-picture-input'
import { setTimeout } from 'timers';

export default {
    mixins: [commonJs, profileMixin],
    data() {

        return {
        }
    },

    
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        PictureInput,
        searchProduct
    },

  mounted(){

        if(this.$route.query.openCreatePin){
 
    $('#createPinModal').modal('show');
  
 $('#createPinInput').focus();
}
    }
}
</script>

<style  lang="scss" scoped>
.no-border-phone{
    border: 1px solid transparent !important;
}

@media(max-width:399px){
.mob-div{
    div{

    flex-basis:100%;
    text-align:left !important;
    }
}
}
.mob {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}

.mob-img {
  height: 100 !important;
}
.nav-tabs {
 max-width: 50%;
//   margin: 0 auto;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}

.nav-tabs {
    border-bottom: 1px solid #fff;
}


.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #4F4F4F;
  background-color: transparent;
  border-color: transparent transparent transparent;
  border-bottom: 2px solid #0094ff !important;
  font-size: 16px;
}

.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #4F4F4F;
  font-size: 16px;

}

.project-tab thead {
  background: #f3f3f3;
  color: #333;
}
.project-tab a {
  text-decoration: none;
}

.tab-div-2{
    align-items:center; background:#fff; border-radius:10px; padding:20px;
}

.disable-input{

        pointer-events: none !important;
    background: #FAFAFA;
    border: 0px solid;
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    text-align: center;
    color: #000;

  
}
.enable-input{

    background: #fff;
    border: 1px solid #BDBDBD; 
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    text-align: center;
    color: #000;

}
.enable-input:focus{
         border: 1px solid #0094FF; 
  }

  .security-modal{

      padding: 20px 100px !important;

      input{

          min-width: 100%;
      }
      @media(max-width:499px){
 padding: 20px 50px !important;
}
.eye-div i {
    position: absolute;
    left: 91% !important;
    top: 10px;
    cursor: pointer;
}
  }
</style>
